import {Container,Row,Col,Button} from 'react-bootstrap'
import React from "react"
import Layout from "../components/layouts/layout"
import SEO from "../components/seo"
import Hero from '../components/hero/hero'
import {Link} from "gatsby"

export default class IndexPage extends React.Component {

  render() {
    return(
      <Layout>

        <SEO title="About Cardiovascular Risk" 
          description="Are you doing enough to protect your heart? Learn what your CV risk may be, including the risk of a heart attack or stroke. Many with high cholesterol and coronary heart disease have turned to statins. Statins are the first step. But they don’t fully protect against CV risk, including heart attack or stroke."
        />
        <h1 className='d-none'>Let’s be honest about cardiovascular risk after the first heart attack or stroke</h1>

          <Hero />

          <Container className="home-content">

            <div className="px-4">

              <h2><strong>Are we doing enough</strong> TO PROTECT OUR HEARTS, ESPECIALLY AFTER A HEART ATTACK OR STROKE?</h2>

              <Row className="blocked mb-0 border-top-0 mt-0 flex-under-992">

                <Col>
                  <div className="stat">
                    <h3 className="mb-4"><span className="eyebrow">NEARLY</span><br/>95,000,000</h3>
                    <p>Americans have high cholesterol and oftentimes, many are solely focused on controlling it, even though the risk of heart attack or stroke remains.<sup>5,6</sup></p>
                  </div>
                </Col>

                <Col>
                  <div className="stat">
                    <h3 className="mb-4"><span className="eyebrow">NUMBER ONE</span><br/>KILLER</h3>
                    <p>Cardiovascular disease is the number one killer of women and men, and after a first heart attack, around 20% of patients age 45 and older will have another heart attack within 5 years.<sup>7-10</sup></p>
                  </div>
                </Col>

              </Row>

              

              <Row className="blocked border-center-none border-top-none mt-0 mb-0">
                <Col className="my-3 py-3 mb-0 mt-4">
                  <div className="stat stat-wide">
                    <h3>25-35%</h3>
                    <p>is how much statins reduce your odds of a life-threatening CV event. Statins are the first step—but they don’t fully protect you from a serious heart attack or stroke. There's more you can do.<sup>6</sup></p>
                  </div>
                </Col>
              </Row>

              <Row className="blocked sm-blocked border-center-none border-top-none mt-0 mb-0">
                <Col className="my-3 py-3 mb-0 mt-4">
                  <div  className="">
                    <h3 className="text-default fs-48 fw-600">It's clear that cardiovascular disease, including heart attack or stroke, is too risky to ignore.</h3>
                    <p style={{
                              fontSize: "24px",
                              fontWeight: 200,
                              marginTop: "5px",
                              marginBottom: "25px"
                              }}
                    >
                    Millions of patients at risk of heart attack or stroke may be unknowingly receiving medications that have not shown to reduce CV risk on top of statins.<sup>11,12</sup></p>
                    <p style={{
                              fontSize: "24px",
                              fontWeight: 200,
                              marginTop: "5px",
                              marginBottom: "25px"
                              }}
                    >
                    Talk to your doctor today about switching to FDA-approved therapies, as you may still be at risk of having a heart attack or stroke.<sup>12,13</sup></p>
                    
                  </div>
                </Col>
              </Row>

              <h3 className="callout-light font-color-primary fs-24 fw-600 text-center text-uppercase font-weight-bold pt-4 pb-4">
                AFTER A FIRST HEART ATTACK OR STROKE, THERE'S MORE YOU CAN DO TO LOWER THE RISK OF ANOTHER<sup>1</sup>
              </h3>

              <p className="text-center"><Link class="btn btn-primary" to="/heart-videos">SEE HEART VIDEOS</Link></p>
              
              <p className='reference-text'>
                <strong>References: 1. </strong>Bansilal S, Castellano JM, Fuster V. Global burden of CVD: focus on secondary prevention of cardiovascular disease. <em>Int J Cardiol</em>. 2015;201(suppl 1):S1-S7. 
                <strong> 2. </strong>Tsao CW, Aday AW, Almarzooq ZI, et al. Heart disease and stroke statistics—2022 update: a report from the American Heart Association. <em>Circulation</em>. 2022;145:e153-e639. 
                <strong> 3. </strong>Samsa GP, Matchar DB, Goldstein L, et al. Utilities for major stroke: results from a survey of preferences among persons at increased risk for stroke. <em>Am Heart J</em>. 1998;136(4 Pt 1):703-713. 
                <strong> 4. </strong>Solomon NA, Glick HA, Russo CJ, Lee J, Schulman KA. Patient preferences for stroke outcomes. <em>Stroke</em>. 1994;25(9):1721-1725. 
                <strong> 5. </strong>Virani SS, Alonso A, Aparicio HJ, et al; on behalf of the American Heart Association Council on Epidemiology and Prevention Statistics Committee and Stroke Statistics Subcommittee. 2021 Heart disease and stroke statistics update fact sheet at-a-glance. <span className='break-all'>https://www.heart.org/-/media/phd-files-2/science-news/2/2021-heart-and-stroke-stat-update/2021_heart_disease_and_<br className='desktop-only'/>stroke_statistics_update_fact_sheet_at_a_glance.pdf.</span> Accessed March 8, 2022. 
                <strong> 6. </strong>Ganda OP, Bhatt DL, Mason RP, Miller M, Boden WE. Unmet need for adjunctive dyslipidemia therapy in hypertriglyceridemia management. <em>J Am Coll Cardiol</em>. 2018;72(3):330-343. 
                <strong> 7. </strong>Centers for Disease Control and Prevention. Women and heart disease. https://www.cdc.gov/heartdisease/women.htm. Accessed March 8, 2022. 
                <strong> 8. </strong>Centers for Disease Control and Prevention. Heart disease facts. https://www.cdc.gov/heartdisease/facts.htm. Accessed March 8, 2022. 
                <strong> 9. </strong>National Institutes of Health. Coronary heart disease. https://www.nhlbi.nih.gov/health-topics/coronary-heart-disease. Accessed March 8, 2022. 
                <strong> 10. </strong>American Heart Association. Life after a heart attack. https://www.heart.org/en/health-topics/heart-attack/life-after-a-heart-attack. Accessed March 8, 2022. 
                <strong> 11. </strong>What you need to know about dietary supplements. US Food and Drug Administration website. <span className='break-all'>https://www.fda.gov/food/dietarysupplements/usingdietarysupplements/ucm109760.htm.</span> Updated November 2017. Accessed March 8, 2022. 
                <strong> 12. </strong>Department of Health and Human Services. [Docket no. FDA–2016–N–1127]: AbbVie Inc., et al; Withdrawal of approval of indications related to the coadministration with statins in applications for niacin extended-release tablets and fenofibric acid delayed-release capsules. <em>Federal Register</em>. 2016;81(74):22612-22613. 
                <strong> 13. </strong>ACCORD Study Group; Ginsberg HN, Elam MB, Lovato LC, et al. Effects of combination lipid therapy in type 2 diabetes mellitus. <em>N Engl J Med</em>. 2010;362(17):1563-1574.
              </p>
            </div>

          </Container>

      </Layout>
    )
  }

  componentDidMount(){
    //Detect if the browser is Safari
    var ua = navigator.userAgent.toLowerCase(); 
    if (ua.indexOf('safari') != -1) { 
      if (ua.indexOf('chrome') > -1) {
        return false;
      } else {
        //Change top margin of crashing text
        var scene2 = document.getElementById("scene2").childNodes;
        for (var i = 0; i<scene2.length; i++) {
          var H3 = scene2[i].getElementsByTagName('h3');
          for (var j = 0; j<H3.length; j++) {
            H3[j].style.marginTop = 0;
          }
          //Center top position of "Deaths"
          var aniText = scene2[i].getElementsByClassName('ani-text');
          for (var j = 0; j<aniText.length; j++) {
            aniText[j].style.setProperty('top', '0', 'important');
          }
        }
        var scene3 = document.getElementById("scene3").childNodes;
        for (var i = 0; i<scene3.length; i++) {
          var H3 = scene3[i].getElementsByTagName('h3');
          for (var j = 0; j<H3.length; j++) {
            H3[j].style.marginTop = 0;
          }
        }
        var scene4 = document.getElementById("scene4").childNodes;
        for (var i = 0; i<scene4.length; i++) {
          var H3 = scene4[i].getElementsByTagName('h3');
          for (var j = 0; j<H3.length; j++) {
            H3[j].style.marginTop = 0;
          }
        }
      }
    }
    //Detect if browser is FF
    var isFF = !!navigator.userAgent.match(/firefox/i);
    if (isFF){
      var blockElements = document.getElementsByClassName("block")
      for (var i = 0; i < blockElements.length; i ++) {
        //Change top padding to center numbers in container
        blockElements[i].style.paddingTop = "7px";
      }
      var smallBlockElements = document.getElementsByClassName("block-small")
      for (var i = 0; i < smallBlockElements.length; i ++) {
        //Change top padding to center numbers in container
        smallBlockElements[i].style.paddingTop = "4px";
      }
    }
  }
}

