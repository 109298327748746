import {Jumbotron} from 'react-bootstrap'
import React from "react"
import "./hero.scss"
import "./hero-mobile.scss"
import "./animate.scss"

export default class HeroComponent extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      // Start with this scene
      current_scene : 1,
      // Total scenes

      total_scenes : 4,
      // Scroll delay to prevent users from scrolling while the scene is animating
      // default, changes depending on whether scene already animated
      scroll_delay : 1000,
    }
  }

  componentWillMount(){
  }
  componentDidMount(){
    this.heroLoader();
    this.scrollManager()
    window.addEventListener("resize", this.heroResizeToFull);
    window.addEventListener("load", this.heroResizeToFull);
    this.heroResizeToFull();

    //Detect if OS is "Win"
    if (navigator.appVersion.indexOf("Win") !== -1) {
      //block padding-top fix for scene 2
      var scene2Children = document.getElementById("scene2").childNodes;
      for (var i = 0; i < scene2Children.length; i++) {
        var blocks = scene2Children[i].getElementsByClassName("block");
        for (var j = 0; j < blocks.length; j++) {
          blocks[j].style.paddingTop = "7px";
        }
      }

      //block padding-top fix for scene 2
      var scene3Children = document.getElementById("scene3").childNodes;
      for (var i = 0; i < scene3Children.length; i++) {
        var blocks = scene3Children[i].getElementsByClassName("block");
        for (var j = 0; j < blocks.length; j++) {
          blocks[j].style.paddingTop = "7px";
        }
      }

      //block padding-top fix for scene 2
      var scene4Children = document.getElementById("scene4").childNodes;
      for (var i = 0; i < scene4Children.length; i++) {
        var blocks = scene4Children[i].getElementsByClassName("block");
        for (var j = 0; j < blocks.length; j++) {
          blocks[j].style.paddingTop = "7px";
        }
      }
    }
  }

  componentWillUnmount(){
    //Unmount listeners on page change.
    window.removeEventListener("resize", this.heroResizeToFull)
    window.removeEventListener("load", this.heroResizeToFull);
    window.removeEventListener("onscroll", this.scrollAction);
    window.removeEventListener("wheel", this.scrollAction);
    window.removeEventListener("touchmove", this.scrollAction);
    document.body.classList = [ ]
  }

  componentDidUpdate(){
    this.heroResizeToFull();
  }



  handleLoad = () => {
    document.getElementsByClassName("loading")[0].style.display = "none"
  }

  heroLoader = () =>{

  	var img = document.getElementById('scene1').style.backgroundImage;
  	console.log(img);

  }

  heroResizeToFull = () => {

    // Get window height and header height.
    var window_height = window.innerHeight;
    var header_height = document.getElementById("header").offsetHeight;

    // Set height of the scene container using the values above
      document.getElementsByClassName("hero")[0].style.height = 2 + window_height - header_height + "px";
        // console.log(window_height,header_height)
  }


      // SCROLL LISTENER
      // This function is called by the scroll listener. It's constantly checking the
      // direction of the wheel scroll and changing the scene based on the direction.

  scrollAction = (event) => {

        // Are animations currently in progress?
        if (document.body.classList.contains("animating") || document.body.classList.contains("done-animating") ) {
          

          // Do nothing

        // else, continue with function...
        } else {
          
          // If the user is scrolling UP
          if (event.deltaY < 0) {

            this.scrollDirection("u");


          // If the user is scrolling DOWN

          } else {

            this.scrollDirection("d");


          }

        }

      }

  // Disable scrolling on the page when the body has a class of "no-scroll"
  disableScrolling(){
    document.body.classList.add("no-scroll");
  }

  // Enable scrolling on the page when the class "no-scroll" is removed from body
  enableScrolling(){
    document.body.classList.remove("no-scroll");
  }


    // GO TO SCENE
    // This function changes the scene. The 'scene' argument is a value that represents
    // the scene to display. This function also hides the current scene by removing the
    // 'active' class name.

  goToScene = (scene) => {

      // New scene ID
      var new_scene = "scene"+scene;

      // Remove active state from current scene
      document.getElementsByClassName('active')[0].classList.remove("active");

      // Add active state to new scene
      document.getElementById(new_scene).classList.add("active");

      // Add 'animated state to scene to indicate that the scene has already animated
      document.getElementById(new_scene).classList.add("animated");

      // Remove 'before' class on current scene
      document.getElementById(new_scene).classList.remove("before");

      // Add 'before' class to the previous scene siblings
      if (scene !== 1) {
        document.getElementsByClassName('active')[0].previousSibling.classList.add("before");
      }

      // Assign an "animating" class to body to communicate to all functions
      // that an animation is occuring. While in this state, the scroll listener
      // will ignore any scrolling attemps
      document.body.classList.add("animating");



      // If the scene has already animated, then reduce the delay
      if ( document.getElementsByClassName('active')[0].classList.contains("animated") ) {
        this.setState({
            scroll_delay : 500,
        })

      }

      // If the screen hasn't animated yet, then restore the delay to the default value
      if ( document.getElementsByClassName('active')[0].classList.contains("animated") ) {
        this.setState({
            scroll_delay : 1000,
        })
      }

      // Remove "animating" class from body after X milliseconds. This will
      setTimeout(function() {
        document.body.classList.remove("animating");
      }, this.state.scroll_delay);

  }

  clickDown = () => {

    
    
    // Are animations currently in progress?
    if (document.body.classList.contains("animating") || document.body.classList.contains("done-animating") ) {
          
      console.log('a');
      // Do nothing

    // else, continue with function...
    } else {
      
      this.scrollDirection("d");

      if (this.state.current_scene < 4) {
        document.getElementById('scene'+(this.state.current_scene + 1)).classList.add("active");
        document.getElementById('scene'+(this.state.current_scene + 1)).classList.add("animated");
    } else {
      document.getElementById('scene'+this.state.current_scene).classList.add("active");
      document.getElementById('scene'+this.state.current_scene).classList.add("animated");
    }
  }
}


  scrollDirection = (dir) => {

    if (dir === "u") {

      // Is the user at the top of the page?
      if (window.scrollY===0) {

        // Disable scrolling
        this.disableScrolling();

        // Hide homepage content at the bottom of the page
        document.getElementsByClassName('home-content')[0].classList.remove("on");

        // Show the chevron
        // document.getElementsByClassName('chevron-down')[0].classList.add("on");

        // Check if user is NOT on the first scene
        if (this.state.current_scene > 1) {

          // Go to the previous scene
          this.setState({
            current_scene: this.state.current_scene - 1
          })

          this.goToScene(this.state.current_scene);

          // Hide the chevron
          // document.getElementsByClassName('chevron-down')[0].classList.remove("on");

        // If user is on any other scene aside from the first...
        } else {

          // Do nothing

        }

      }

    } else

    if (dir === "d") {

      // Is the user on the final scene?
      if (this.state.current_scene === this.state.total_scenes) {

        // Enable scrolling
        this.enableScrolling();

        // Give the page content a class of "on" which will fade the content into
        // the page.
        document.getElementsByClassName('home-content')[0].classList.add("on");

        // Prevent user from animating back through the scenes. The last scene
        // will be the only scene the user will see if they scroll back up.
        document.body.classList.add("done-animating");


      // The user is not on the final scene

      } else {

        // Disable scrolling
        this.disableScrolling();

        // Set and go to the next scene
        this.setState({
          current_scene: this.state.current_scene + 1,
        })
        this.goToScene(this.state.current_scene);

      }


      // If the current scene is not the first scene, then hide the chevron. Chevron
      // should only appear on the first scene to indicate that the user can scroll
      // for more content.

      if (this.state.current_scene > 1 ) {
        // Hide the chevron
        // document.getElementsByClassName('chevron-down')[0].classList.remove("on");
      }

    }


  }

  scrollManager = () => {


    document.getElementById('scene'+this.state.current_scene).classList.add("active");
    document.getElementById('scene'+this.state.current_scene).classList.add("animated");

    // Call function to listen to the wheel scroll
    window.addEventListener("onscroll", this.scrollAction);
    window.addEventListener("wheel", this.scrollAction);
    window.addEventListener("touchmove", this.scrollAction);
    //detectswipe('home-hero',detectSwipeDirection);

    // Disable scrolling on page load
    this.disableScrolling();

  }










  render(){

    return (
    <div>

      <Jumbotron id="home-hero" className="row hero">

        <div id="scene1" className="scene h-100">
          <div className="scene-content">
            <h2 className="ani ani-up ani-fade-in ani-delay-500 uppercase">
              <strong>LET’S BE HONEST</strong> about cardiovascular risk after the first heart attack or stroke
            </h2>
          </div>
        </div>

        <div id="scene2" className="scene">
          <div className="scene-content">
            <h2 className="ani-wrap text-uppercase ani ani-up ani-fade-in ani-delay-250 copy">
              <strong>After a heart attack, the recurrence rate</strong> of another cardiovascular event or heart procedure is up to
            </h2>
            <h2 className="ani-wrap text-uppercase highlight">
            <span className="ani-wrap">
              <span className="block ani ani-up ani-delay-500">50<sup>%</sup></span>
              <span className="block block-small ani ani-up ani-delay-750 text-uppercase">within a year.<sup>1</sup></span>
            </span>
            </h2>
          </div>
        </div>

        <div id="scene3" className="scene">
          <div className="scene-content">
          <h3 className="ani ani-up ani-fade-in ani-delay-250">EVERY YEAR, MORE THAN</h3>

            <h2 className="ani-wrap">
              <span className="ani-wrap">
                <span className="block ani ani-left-to-right number ">1.5</span>
              </span>
              <span className="ani-wrap">
                <span className="ani-text ani ani-fade-in ani-left-to-right ani-delay-500 label">million</span>
              </span>
            </h2>
            <h3 className="ani ani-up ani-fade-in ani-delay-750">PEOPLE IN THE UNITED STATES <strong>SUFFER A HEART ATTACK <span className='text-nowrap'>OR STROKE.<sup className='un-bold'>2</sup></span></strong></h3>
          </div>
        </div>

        <div id="scene4" className="scene">
          <div className="scene-content">
          <h3 className="ani ani-up ani-fade-in ani-delay-500">For many people, the risk of being</h3>
            <h2 className="ani-wrap">
              <span className="ani-wrap">
                <span className="block ani ani-left-to-right text-uppercase fs-50">impaired by a stroke</span>
              </span>
            </h2>
            <h3 className="ani ani-up ani-fade-in ani-delay-500"><strong>is feared more than death from heart disease, including heart attack.<sup className='un-bold'>3,4</sup></strong></h3>
          </div>
        </div>

        <div className="chevron-down on" onClick={this.clickDown}></div>
      </Jumbotron>
    </div>

    )
  }
}
